import {
  BoltIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  MegaphoneIcon,
  CreditCardIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import React from "react";

import { articleTypes } from "../../constants/article-types";

const Topics = ({ articleMap }) => {
  console.log(articleMap);
  return (
    <div className="max-w-7xl mx-auto px-6 py-16">
      <div className="py-12">
        <div className="mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">A better way to send money.</h2>
          <dl className="space-y-10 lg:grid lg:grid-cols-3 lg:gap-10 lg:space-y-0">
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <BoltIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  General Topics
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.GENERAL].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-indigo-600 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <MegaphoneIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Standup Topics
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.STANDUPS].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-indigo-600 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Participant Topics
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.PARTICIPANTS].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-indigo-600 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <CreditCardIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Billing Topics
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.BILLING].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-indigo-600 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <ChartBarSquareIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Dashboard Topics
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.DASHBOARD].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-indigo-600 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <QuestionMarkCircleIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Need additional support?
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  <li className="leading-5">
                    <a
                      className="text-indigo-600 hover:underline"
                      href="https://standupwizard.com/contact"
                    >
                      Get in touch with us
                    </a>
                  </li>
                  <li className="leading-5">
                    <a
                      className="text-indigo-600 hover:underline"
                      href="https://standupwizard.com/faq"
                    >
                      Frequently Asked Questions
                    </a>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Topics;
