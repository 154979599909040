import { articleTypes } from "../constants/article-types";

export const mapArticles = (articles) => {
  const articleMap = {
    [articleTypes.GENERAL]: [],
    [articleTypes.STANDUPS]: [],
    [articleTypes.PARTICIPANTS]: [],
    [articleTypes.BILLING]: [],
    [articleTypes.DASHBOARD]: [],
  };

  for (let i = 0; i < articles.length; i++) {
    const article = articles[i];
    const slug = article.fields.slug;

    if (slug.startsWith(`/${articleTypes.GENERAL}/`)) {
      articleMap[articleTypes.GENERAL].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.STANDUPS}/`)) {
      articleMap[articleTypes.STANDUPS].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.PARTICIPANTS}/`)) {
      articleMap[articleTypes.PARTICIPANTS].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.BILLING}/`)) {
      articleMap[articleTypes.BILLING].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.DASHBOARD}/`)) {
      articleMap[articleTypes.DASHBOARD].push({
        title: article.frontmatter.title,
        slug,
      });
    }
  }

  return articleMap;
};
